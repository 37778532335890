<template>
    <div v-for="(item, index) in blok.Items" :key="item._uid" class="feature-list-item">
        <div class="feature-list-item__number-wrapper">
            <div
                class="feature-list-item__number"
                :style="{
                    backgroundColor: blok.BackgroundColor?.color ? blok.BackgroundColor.color : '#FFF',
                }"
            >
                {{ index + 1 }}
            </div>
        </div>
        <div class="feature-list-item__content">
            <div class="feature-list-item__title">{{ item[mappedTitle] }}</div>
            <div class="feature-list-item__text">{{ item[mappedText] }}</div>
        </div>
    </div>
</template>

<script lang="ts" setup>
const { blok, type } = defineProps<{ blok: any; type: 'BookWithUs' | 'HowItWorks' }>();

const mappedTitle = computed(() => (type === 'BookWithUs' ? 'Title' : 'title'));
const mappedText = computed(() => (type === 'BookWithUs' ? 'Text' : 'text'));
</script>

<style lang="postcss" scoped>
.feature-list-item {
    @apply flex md:flex-col gap-3 md:gap-8 text-left md:text-center;
    &:first-child {
        .feature-list-item__number:after {
            content: '';
            @apply hidden md:block absolute top-1/2 h-[1px] left-full -mt-1.5;
            background-image: linear-gradient(90deg, #b4e076, #b4e076 50%, transparent 50%, transparent 100%);
            background-size: 8px 1px;
            background-repeat: repeat;
            width: 1300px;
        }
    }
    &:last-child {
        @apply z-20;
        .feature-list-item__number:after {
            content: '';
            @apply hidden md:block absolute top-0 h-full left-full w-36 bg-inherit;
        }
    }
    &__number-wrapper {
        @apply text-center;
    }
    &__number {
        @apply relative inline-block text-8xl leading-[4rem] text-brand-secondary-500 font-bold w-14 md:w-24 h-20 z-10;
    }
    &__title {
        @apply text-base md:text-xl mb-1 font-bold;
    }
    &__text {
        @apply text-base mb-1 font-normal;
    }
    &__link {
        @apply text-base font-semibold text-text-titanium hover:underline;
    }
}
</style>
